import Tabbable from 'node_modules/tabbable'
import Component from '../../lib/component.js'

export default class SupernavComponent extends Component {
    static scrollListener () {
        return true
    }

    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.persistActiveSubnav = Boolean(this.el.querySelector(
            '.supernav-menu-subnav-toggle[aria-expanded="true"]')
            || this.el.querySelector(
                '.supernav-menus-panels [type="radio"]:checked'))

        this.els = {
            dropdown: this.el.querySelector('.supernav-dropdown'),
            overlay: this.el.querySelector('.supernav-overlay'),
            panels: this.el.querySelector('.supernav-menus-panels')
        }

        this.onSubnavToggleClick = this.onSubnavToggleClick.bind(this)
        this.onSubnavToggleKeydown = this.onSubnavToggleKeydown.bind(this)
        this.onSubnavToggleMouseEnter = this.onSubnavToggleMouseEnter.bind(this)
        this.onSubnavToggleMouseLeave = this.onSubnavToggleMouseLeave.bind(this)
        this.onSubnavToggleFocus = this.onSubnavToggleFocus.bind(this)

        this.onDropdownToggleClick = this.onDropdownToggleClick.bind(this)
        this.onDropdownToggleKeydown = this.onDropdownToggleKeydown.bind(this)
        this.onDropdownKeydown = this.onDropdownKeydown.bind(this)

        this.onMenusKeydown = this.onMenusKeydown.bind(this)
        this.onPanelsKeydown = this.onPanelsKeydown.bind(this)

        this.render()
        this.listen()

        this.emit('resize')
    }

    listen () {
        this.getSubnavToggles()
            .forEach((el) => {
                el.addEventListener('click', this.onSubnavToggleClick)
                el.addEventListener('keydown', this.onSubnavToggleKeydown)
                el.addEventListener('mouseenter', this.onSubnavToggleMouseEnter)
                el.addEventListener('mouseleave', this.onSubnavToggleMouseLeave)
                el.addEventListener('focus', this.onSubnavToggleFocus)
            })

        this.getDropdownToggles()
            .forEach((el) => {
                el.addEventListener('click', this.onDropdownToggleClick)
                el.addEventListener('keydown', this.onDropdownToggleKeydown)
            })

        this.on('resize', this.onSubnavResize)
        this.on('resize', this.onDropdownResize)

        if (this.els.dropdown)
            this.el
                .addEventListener('keydown', this.onDropdownKeydown)

        if (this.el.querySelector('.supernav-menus'))
            this.el.querySelector('.supernav-menus')
                .addEventListener('keydown', this.onMenusKeydown)

        if (this.els.panels)
            this.els.panels
                .addEventListener('keydown', this.onPanelsKeydown)

        if (!this.els.overlay)
            return

        this.els.overlay
            .addEventListener('click', this.onDropdownToggleClick)
    }

    onSubnavToggleClick (e) {
        if (!e.target)
            return

        const target = e.target.getAttribute('data-controls')
                || e.target.getAttribute('aria-controls')
            ? e.target
            : e.target.parentNode

        if (!target.getAttribute('data-controls')
                && !target.getAttribute('aria-controls'))
            return

        this.setActiveSubnav(this.el
            .querySelector(`#${target.getAttribute('data-controls')
                || target.getAttribute('aria-controls')}`))

        if (target.getAttribute('aria-expanded') === 'true')
            this.scrollIntoView(target)
    }

    onSubnavToggleKeydown (e) {
        if (!this.isKeydownClick(e))
            return

        e.preventDefault()
        e.stopPropagation()

        this.onSubnavToggleClick(e)
    }

    onSubnavToggleMouseEnter (e) {
        if (this.isFullWidthSubnav()
                || e.target.getAttribute('aria-expanded') === 'true'
                || this.isResizing)
            return

        this.onSubnavToggleClick(e)
    }

    onSubnavToggleMouseLeave (e) {
        const toSubnavEl = e.toElement
            && (e.toElement.classList.contains('supernav-menu-subnav')
            || e.toElement
                .classList.contains('supernav-menu-subnav-toggle')
            || (e.toElement.parentNode
                && e.toElement.parentNode
                && e.toElement.parentNode.classList
                && e.toElement.parentNode
                    .classList.contains('supernav-menu-subnav-toggle')))

        if (this.isFullWidthSubnav()
                || this.isResizing
                || e.target.getAttribute('aria-expanded') !== 'true'
                || toSubnavEl
                || (!this.persistActiveSubnav
                    && !toSubnavEl
                    && e.target === document.activeElement))
            return

        this.onSubnavToggleClick(e)
    }

    onSubnavToggleFocus (e) {
        if (e.target)
            this.scrollIntoView(e.target)
    }

    onSubnavResize () {
        if (!this.els.panels)
            return

        this.isResizing = true

        if (Tabbable(this.el).indexOf(document.activeElement) >= 0)
            document.activeElement.setAttribute('data-refocus', 'true')

        const isPanelVisible = !this.isFullWidthSubnav()

        this.getSubnavToggles()
            .filter(el => el.getAttribute('data-controls'))
            .map(el => ({
                el: el,
                controls: el.getAttribute('aria-controls'),
                subnavCtrls: el.getAttribute('data-controls'),
                panelCtrls: el.getAttribute('data-controls-panel')
            }))
            .forEach(obj => {
                let val

                if (!isPanelVisible && obj.controls !== obj.subnavCtrls) {
                    val = obj.subnavCtrls

                    if (obj.el.parentNode
                            && obj.el.parentNode.getAttribute('aria-owns'))
                        obj.el.parentNode.removeAttribute('aria-owns')
                }

                if (isPanelVisible && obj.controls !== obj.panelCtrls) {
                    val = obj.panelCtrls

                    if (obj.el.parentNode)
                        obj.el.parentNode
                            .setAttribute('aria-owns', obj.panelCtrls)
                }

                if (val)
                    obj.el.setAttribute('aria-controls', val)
            })

        clearTimeout(this.onSubnavResizeDebounce)

        this.onSubnavResizeDebounce = setTimeout(() =>
            this.onSubnavRefocus(), 200)
    }

    onSubnavRefocus () {
        this.isResizing = false
        const refocus = this.el.querySelector('[data-refocus]')

        if (!refocus)
            return

        if (refocus === document.activeElement)
            return refocus.removeAttribute('data-refocus')

        const path = []
        let node = refocus

        while (node !== document.body) {
            path.push(node)
            node = node.parentNode
        }

        const menuEl = this.el
            .querySelector('.supernav-menu [data-expanded="true"]')

        const isMenuChild = path.indexOf(menuEl) >= 0

        const panelEl = this.els.panels
            .querySelector('[data-expanded="true"]')

        const isPanelChild = path.indexOf(panelEl) >= 0

        if (!isPanelChild && !isMenuChild)
            return refocus.removeAttribute('data-refocus')

        const setFocus = (parent) => {
            refocus.removeAttribute('data-refocus')

            const el = Tabbable(parent)
                .filter(el => el.outerHTML === refocus.outerHTML)[0]

            return this.setFocus(el || parent)
        }

        if (isMenuChild && panelEl)
            return setFocus(panelEl)

        if (isPanelChild && menuEl)
            return setFocus(menuEl)

        refocus.removeAttribute('data-refocus')
    }

    onDropdownToggleClick (e) {
        this.el.classList
            .toggle('supernav-dropdown-expanded')

        this.getDropdownToggles()
            .forEach(node => node.setAttribute('aria-expanded',
                node.getAttribute('aria-expanded') !== 'true'))

        const isExpanded = this.el.classList
            .contains('supernav-dropdown-expanded')

        if (this.els.overlay
                && this.el.parentNode)
            Array.from(this.el.parentNode.children)
                .filter(node => node !== this.el
                    && !node.tagName.match(/script$/i))
                .forEach(node => node.setAttribute('aria-hidden', isExpanded))

        this.onDropdownResize()
        this.onDropdownToggleRefocus(e)
    }

    onDropdownToggleKeydown (e) {
        if (!this.isKeydownClick(e))
            return

        e.preventDefault()
        e.stopPropagation()

        this.onDropdownToggleClick(e)
        this.onDropdownToggleRefocus(e)
    }

    onDropdownToggleRefocus (e) {
        const toggles = Array.from(this.el.querySelectorAll(
            `[aria-controls="${(this.els.dropdown || {}).id}"]`))

        if (!toggles.length)
            return

        let activeEl

        if (e.target.classList.contains('supernav-toggled-hide')
                && toggles[toggles.indexOf(e.target) + 1]
                && e.target.nextElementSibling
                && e.target.nextElementSibling
                    === toggles[toggles.indexOf(e.target) + 1]
                && e.target.nextElementSibling.classList
                    .contains('supernav-toggled-show'))
            activeEl = e.target.nextElementSibling

        if (e.target.classList.contains('supernav-toggled-show')
                && toggles[toggles.indexOf(e.target) - 1]
                && e.target.previousElementSibling
                && e.target.previousElementSibling
                    === toggles[toggles.indexOf(e.target) - 1]
                && e.target.previousElementSibling.classList
                    .contains('supernav-toggled-hide'))
            activeEl = e.target.previousElementSibling

        if (e.target.classList.contains('supernav-toggled-hide')
                && e.target.classList.contains('supernav-icon')
                && e.target.classList.contains('mu-icon-search')
                && e.target.nextElementSibling
                && Tabbable(e.target.nextElementSibling).length
                && Tabbable(e.target.nextElementSibling)[0].type === 'search')
            activeEl = Tabbable(e.target.nextElementSibling)[0]

        if (activeEl)
            return this.setFocus(activeEl)
    }

    onDropdownKeydown (e) {
        if (!(e.keyIdentifier || e.key || '').match(/tab/i)
                || !this.el.classList.contains('supernav-dropdown-expanded'))
            return

        const els = Tabbable(this.el)

        const menuEls = Tabbable(this.el
            .querySelector('.supernav-menus'))

        const expandedPanel = this.els.panels
            .querySelector('[data-expanded="true"]')

        const isFocusLastMenuItem = (el) => !this.isFullWidthSubnav()
            && expandedPanel
            && menuEls.indexOf(el) === menuEls.length - 1
            && el.getAttribute('aria-controls') !== (expandedPanel || {}).id

        const isFocusBack = e.shiftKey
            && (els.indexOf(e.target) === 0)

        const isFocusFwd = !e.shiftKey
            && (els.indexOf(e.target) === els.length - 1
                || isFocusLastMenuItem(e.target))

        if (isFocusFwd)
            return this.setFocus(els[0], e)

        if (isFocusBack)
            return this.setFocus(
                isFocusLastMenuItem(menuEls[menuEls.length - 1])
                    ? menuEls[menuEls.length - 1]
                    : els[els.length - 1], e)
    }

    onDropdownResize () {
        if (!this.App.el.getAttribute('data-min-height'))
            this.App.el.setAttribute('data-min-height',
                this.getBrowser('name').match(/ie|edge/)
                    ? '100%'
                    : window.getComputedStyle(this.App.el).minHeight)

        this.App.el.style.minHeight = this.App.el
            .getAttribute('data-min-height')

        if (!this.el.classList.contains('supernav-dropdown-expanded')
                || !this.els.dropdown)
            return

        const dropdownBounds = this.els.dropdown.getBoundingClientRect()
        const minHeight = dropdownBounds.top + dropdownBounds.height

        if (this.App.el.clientHeight > minHeight
                && !this.isFullWidthSubnav())
            return

        this.App.el.style.minHeight = `${minHeight / 16}rem`
    }

    onMenusKeydown (e) {
        if (!(e.keyIdentifier || e.key || '').match(/tab/i)
                || this.isFullWidthSubnav())
            return

        const expandedPanel = this.el
            .querySelector('.supernav-menu-subnav-panel[data-expanded="true"]')

        const expandedEls = expandedPanel
            ? Tabbable(expandedPanel)
            : null

        if (!expandedPanel || !expandedEls.length)
            return

        if (!e.shiftKey
                && e.target.classList
                    .contains('supernav-menu-subnav-toggle')
                && e.target.getAttribute('aria-expanded') === 'true'
                && e.target.getAttribute('aria-controls') === expandedPanel.id)
            return this.setFocus(expandedEls[0], e)

        const els = Tabbable(this.el)

        if (e.shiftKey
                && els[els.indexOf(e.target) - 1]
                && els[els.indexOf(e.target) - 1]
                    .getAttribute('aria-expanded') === 'true'
                && els[els.indexOf(e.target) - 1]
                    .getAttribute('aria-controls') === expandedPanel.id)
            return this.setFocus(expandedEls[expandedEls.length - 1], e)
    }

    onPanelsKeydown (e) {
        if (!(e.keyIdentifier || e.key || '').match(/tab/i))
            return

        const els = Tabbable(this.els.panels
            .querySelector('[data-expanded=true]'))

        const isFocusBack = e.shiftKey
            && els.indexOf(e.target) === 0

        const isFocusFwd = !e.shiftKey
            && els.indexOf(e.target) === els.length - 1

        if (!isFocusBack && !isFocusFwd)
            return

        const activeToggle = this.el
            .querySelector('.supernav-menu-subnav-toggle[aria-expanded="true"]')

        if (isFocusBack && activeToggle)
            return this.setFocus(activeToggle, e)

        const tabbables = Tabbable(this.el)
        const nextIdx = tabbables.indexOf(activeToggle) + 1
        const nextEl = els.indexOf(tabbables[nextIdx]) < 0
            ? tabbables[nextIdx]
            : null

        if (isFocusFwd && nextEl)
            return this.setFocus(nextEl, e)
    }

    isFullWidthSubnav () {
        return !this.els.panels
            || window.getComputedStyle(this.els.panels).display === 'none'
    }

    isKeydownClick (e) {
        return e.keyCode === 32
            || (e.keyIdentifier || e.key || '').match(/^(enter)$/i)
    }

    scrollIntoView (el) {
        if (!el || el !== document.activeElement)
            return

        const getStyle = (node, prop) => Number(((window
            .getComputedStyle(node) || {})[prop] || '0px')
            .replace(/px/g, ''))

        const elScrollY = el.getBoundingClientRect().top
            - (getStyle(el, 'marginTop')
                + getStyle(el.parentNode, 'marginTop'))

        const vh = 'innerHeight' in window
            ? window.innerHeight
            : document.documentElement.clientHeight || null

        const isScrollTo = vh
            && (elScrollY < 0 || el.scrollY > vh)

        if (!isScrollTo)
            return

        return !this.App.Scroller
            ? el.scrollIntoView()
            : this.App.Scroller.scrollTo({
                y: elScrollY + this.App.Scroller.state.get('scrollY')
            })
    }

    getDropdownToggles () {
        return Array.from(
            this.el.querySelectorAll('.supernav-dropdown-toggle'))
    }

    getSubnavToggles () {
        return Array.from(
            this.el.querySelectorAll('.supernav-menu-subnav-toggle'))
    }

    getSubnavs () {
        return Array.from(
            this.el.querySelectorAll('.supernav-menu-subnav'))
    }

    setActiveSubnav (el) {
        if (!el || !el.id)
            return

        if (!this.persistActiveSubnav
                || (this.persistActiveSubnav
                    && el.getAttribute('data-expanded') !== 'true'))
            this.getSubnavs()
                .forEach(node => (node.id !== el.id
                        && node.id !== `${el.id}-panel`)
                        || (node.getAttribute('data-expanded') === 'true')
                    ? node.removeAttribute('data-expanded')
                    : node.setAttribute('data-expanded', true))

        this.getSubnavToggles()
            .forEach(node => node.setAttribute('aria-expanded',
                (node.getAttribute('data-controls')
                    || node.getAttribute('aria-controls')) === el.id
                && el.getAttribute('data-expanded')))
    }

    setFocus (el, e) {
        if (!el)
            return

        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        el.focus()
        this.scrollIntoView(el)
    }

    render () {
        if (this.props.dropdownToggleId)
            this.renderAccessibleDropdown()

        this.renderAccessibleSubnavs()

        if (this.els.panels)
            this.getSubnavs()
                .filter(el => el.id)
                .forEach(el => {
                    const panel = el.cloneNode(true)
                    panel.id = `${panel.id}-panel`
                    panel.classList.add('supernav-menu-subnav-panel')

                    this.els.panels.appendChild(panel)
                    el.classList.add('display-none-md-up')
                })

        this.getSubnavToggles()
            .concat(this.getDropdownToggles())
            .forEach(node => {
                node.setAttribute('aria-expanded',
                    node.getAttribute('aria-expanded') === 'true')
                node.setAttribute('tabindex', 0)

                if (!this.els.panels)
                    return

                const controls = node.getAttribute('aria-controls')
                const panel = `${controls}-panel`

                if (!this.els.panels.querySelector(`#${panel}`))
                    return

                node.setAttribute('data-controls', controls)
                node.setAttribute('data-controls-panel', panel)
            })

        const activeToggle = this.el.querySelector(
            '.supernav-menu-subnav-toggle[aria-expanded="true"]')

        if (!activeToggle)
            return

        this.setActiveSubnav(this.el
            .querySelector(`#${activeToggle.getAttribute('aria-controls')}`))
    }

    renderAccessibleSubnavs () {
        const tablist = this.el.querySelector('[role="tablist"]')

        if (tablist) {
            tablist.removeAttribute('role')
            tablist.classList.add('display-flex')
        }

        Array.from(this.el
            .querySelectorAll(
                '.supernav-menus-panels > *:not(.supernav-wallpaper)'))
            .forEach(node => node.remove())

        Array.from(this.el
            .querySelectorAll('label.supernav-menu-subnav-toggle'
                + ':not(.supernav-menu-text-label)'))
            .map(node => ({
                el: node.parentNode,
                checkbox: node.parentNode
                    .querySelector(`#${node.getAttribute('for')}`),
                labels: Array.from(node.parentNode.querySelectorAll(`label`))
                    .filter(node => node.classList
                        .contains('supernav-menu-subnav-toggle')
                        || node.classList
                            .contains('supernav-menu-subnav-panel-toggle')),
                subnav: node.parentNode
                    .querySelector('.supernav-menu-subnav')
            }))
            .forEach(obj => {
                if (obj.checkbox)
                    obj.checkbox.remove()

                const subnavId = obj.subnav && obj.subnav.id
                    ? obj.subnav.id
                    : `supernav-menu-subnav-${this.generateGUID()}`

                if (obj.subnav)
                    obj.subnav.id = subnavId

                const textLabels = obj.labels
                    .filter(node => node.classList
                        .contains('supernav-menu-text-label'))

                const caretLabels = obj.labels
                    .filter(node => node.classList
                        .contains('supernav-menu-caret'))

                const panelLabels = obj.labels
                    .filter(label => label.classList
                        .contains('supernav-menu-subnav-panel-toggle'))

                const btn = textLabels.length
                    ? document.createElement('a')
                    : null

                if (btn) {
                    btn.classList.add('supernav-menu-subnav-toggle')
                    btn.setAttribute('role', 'button')
                    btn.setAttribute('aria-controls', subnavId)

                    obj.el.insertBefore(btn, obj.el.children[0])

                    textLabels
                        .forEach(textLabel => {
                            textLabel.removeAttribute('for')
                            textLabel.removeAttribute('tabindex')
                            textLabel.classList
                                .remove('supernav-menu-subnav-toggle')

                            const span = document.createElement('span')
                            btn.appendChild(span)

                            span.outerHTML = textLabel.outerHTML
                                .replace(/(<\/*)(label)(?=>)*/g, '$1span')

                            textLabel.remove()
                        })
                }

                // Update Caret labels
                caretLabels
                    .forEach(caretLabel => {
                        caretLabel.removeAttribute('for')

                        if (Array
                            .from(caretLabel.parentNode.children).indexOf(btn)
                                >= 0) {
                            caretLabel.classList
                                .remove('supernav-menu-subnav-toggle')

                            const span = document.createElement('span')
                            btn.appendChild(span)

                            span.outerHTML = caretLabel.outerHTML
                                .replace(/(<\/*)(label)(?=>)*/g, '$1span')

                            return caretLabel.remove()
                        }

                        if (!obj.subnav)
                            return caretLabel.remove()

                        const caretBtn = document.createElement('a')
                        caretLabel.parentNode
                            .insertBefore(caretBtn, obj.subnav)

                        caretLabel.setAttribute('aria-controls', subnavId)
                        caretLabel.setAttribute('role', 'button')

                        caretBtn.outerHTML = caretLabel.outerHTML
                            .replace(/(<\/*)(label)(?=>)*/g, '$1a')

                        caretLabel.remove()
                    })

                // Remove panel labels
                panelLabels
                    .forEach(panelLabel => {
                        const panelId = panelLabel.getAttribute('for')
                        const panelInput = this.el
                            .querySelector(`#${panelId}`)
                        const panel = this.el
                            .querySelector(`[aria-labelledby="${panelId}"]`)

                        if (panelInput)
                            panelInput.remove()

                        if (panel)
                            panel.remove()

                        if (panelLabel.getAttribute('data-selected') === 'true')
                            Array.from(obj.el
                                .querySelectorAll('.supernav-menu-subnav-toggle'))
                                .forEach(toggle => toggle
                                    .setAttribute('aria-expanded', true))

                        panelLabel.remove()
                    })
            })
    }

    renderAccessibleDropdown () {
        const checkbox = this.el
            .querySelector(`#${this.props.dropdownToggleId}`)
        const labels = Array.from(this.el
            .querySelectorAll(`label[for=${this.props.dropdownToggleId}]`))
        const dropdownId = `supernav-dropdown-${this.generateGUID()}`

        if (checkbox)
            checkbox.remove()

        if (this.els.dropdown)
            this.els.dropdown.id = dropdownId

        labels.forEach(node => {
            const id = node.id = node.id
                || `supernav-dropdown-toggle-${this.generateGUID()}`
            const tmp = document.createElement('a')
            const tmpHTML = node.outerHTML
                .replace(/(<\/*)(label)(?=>)*/g, '$1a')
                .replace(/for(?==")/, 'data-for')

            node.parentNode
                .insertBefore(tmp, node)

            node.remove()
            tmp.outerHTML = tmpHTML

            const btn = this.el.querySelector(`#${id}`)
            const btnExpanded = this.els.dropdown
                ? window.getComputedStyle(this.els.dropdown)
                    .visibility === 'visible'
                : false

            btn.classList.add('supernav-dropdown-toggle')
            btn.setAttribute('role', 'button')
            btn.setAttribute('aria-controls', dropdownId)
            btn.setAttribute('tabindex', 0)

            if (this.els.dropdown)
                btn.setAttribute('aria-expanded', btnExpanded)

            if (btnExpanded
                    && !this.el.classList.contains('supernav-expanded'))
                this.el.classList.add('supernav-expanded')
        })
    }
}
