import Obj from './obj.js'
import Baobab from 'node_modules/baobab'
import detectBrowser from 'node_modules/detect-browser/lib/detectBrowser'

export default class Component extends Obj {
    constructor (opts = {}) {
        super(opts)

        this.state = new Baobab(this.state || {})
        this._features = {}
        this.passiveSupported = false

        try {
            const testOpts = Object.defineProperty({}, 'passive', {
                get: () => {
                    this.passiveSupported = true
                }
            })

            window.addEventListener('test', testOpts, testOpts)
            window.removeEventListener('test', testOpts, testOpts)
        }
        catch (err) {
            console.warn('Passive Listening Not Supported')
        }

        this.addFeatureTest({
            touch: () => 'ontouchstart' in window
                || ('DocumentTouch' in window
                    && document instanceof window.DocumentTouch)
                || (window.navigator.maxTouchPoints || 0) > 0
        })

        if (this.getBrowser('name'))
            this.addFeatureTest({ [this.getBrowser('name')]: true })

        if (!this.el)
            return

        this.addFeaturesToClassName(this.el, 'touch')

        if (this.el.nodeType !== 1
                || !this.el.parentElement
                || !(['MutationObserver'] in window))
            return

        const emitResize = () => this.emit('resize')
        window.addEventListener('resize', emitResize, false)

        const observer = new window.MutationObserver(mutations =>
            mutations
                .filter(mutation => mutation.removedNodes.length)
                .forEach(mutation => {
                    for (let i = 0; i < mutation.removedNodes.length; i++)
                        if (mutation.removedNodes[i] === this.el) {
                            observer.disconnect()

                            this.emit('destroy')
                            this.removeAllListeners()

                            window.removeEventListener('resize', emitResize, false)
                        }
                }))

        observer.observe(this.el.parentElement, { childList: true })
    }

    addFeatureTest (obj) {
        Object.keys(obj)
            .filter(key => (typeof obj[key]).match(/boolean|function/))
            .forEach(key => {
                this._features[key] = typeof obj[key] === 'boolean'
                    ? obj[key]
                    : obj[key]()
            })

        return this
    }

    addFeaturesToClassName (el = this.el || document.documentElement, feature) {
        Object.keys(this.getFeatures())
            .filter(key => !feature
                || (feature
                    && typeof feature === 'string'
                    && key === feature))
            .forEach(key => el.classList
                .add(`${this.getFeatures()[key] ? '' : 'no-'}${key}`))

        return this
    }

    detectCSSProp (str) {
        const style = document.createElement('a').style
        style.cssText = `${str}:auto`

        const camelCase = str.toLowerCase().replace(/(-\w{1})/g,
            str => str.replace(/^-/, '').toUpperCase())

        return style[camelCase] === 'auto'
    }

    getBrowser (str) {
        const browser = detectBrowser(window.navigator.userAgent)
            || { name: null, version: null }

        if (!str)
            return browser

        return browser[str]
    }

    getFeatures () {
        this._features = this._features || {}
        return this._features
    }

    generateGUID () {
        const s4 = () => (((1 + Math.random()) * 0x10000) | 0)
            .toString(16)
            .substring(1)

        return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`
    }
}
