import CarouselComponent from './carousel/carousel'
import ChecklistComponent from './checklist/checklist'
import CounselorMapComponent from './counselor-map/counselor-map'
import DatepickerComponent from './datepicker/datepicker'
import FactsComponent from './facts/facts'
import PaginationFetcherComponent from './pagination-fetcher/pagination-fetcher'
import MediaComponent from './media/media'
import QueryComponent from './query/query'
import SelectComponent from './select/select'
import SupernavComponent from './supernav/supernav'
import SlateFormComponent from './slate-form/slate-form'
import SwitchComponent from './switch/switch'
import TablinksComponent from './tablinks/tablinks'
import TablistComponent from './tablist/tablist'

export const Components = {
    '.supernav': SupernavComponent,
    '.facts': FactsComponent,
    '.counselor-map': CounselorMapComponent,
    '.tabs-links': TablinksComponent,
    '[role="tablist"]': TablistComponent,
    '.carousel': CarouselComponent,
    '.media': MediaComponent,
    '.datepicker': DatepickerComponent,
    '.query': QueryComponent,
    '.select': SelectComponent,
    '.switch': SwitchComponent,
    '.checklist': ChecklistComponent,
    '.pagination-fetcher': PaginationFetcherComponent,
    '.slate-form': SlateFormComponent
}
