import Component from '../../lib/component.js'

export default class PaginationFetcherComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.Parser = 'DOMParser' in window
            ? new window.DOMParser()
            : null

        this.els = {
            itemsContainer: this.el
                .querySelector('[data-pagination-fetcher-items-container]'),
            cta: this.el
                .querySelector('[data-pagination-fetcher-cta]'),
            target: null
        }

        this.render()

        if (!this.els.itemsContainer
                || !this.els.cta
                || !this.els.target
                || !this.Parser)
            return this.el.setAttribute('data-rendered', 'false')

        this.onCtaClick = this.onCtaClick.bind(this)
        this.listen()
    }

    listen () {
        this.els.cta
            .addEventListener('click', this.onCtaClick, false)
    }

    onCtaClick (e) {
        if (!this.els.target
                || !this.els.target.href
                || this.els.target.classList.contains('done'))
            return this.setCtaState('done')

        this.setCtaState('loading')

        window.fetch(this.els.target.href)
            .then(res => {
                if (String(res.status).match(/^[2|3]/))
                    return res.text()

                this.setCtaState('error')
                return false
            })
            .then(res => res
                ? this.parseFetchedItems(res, this.els.target.href)
                : null)
    }

    parseFetchedItems (str, href) {
        if (!str || !str.match(/<html/))
            return null

        const doc = this.Parser.parseFromString(str, 'text/html')
        const newItemsContainer = doc
            ? doc.querySelector(
                '.pagination-fetcher [data-pagination-fetcher-items-container]')
            : null
        const newTarget = doc
            ? doc.querySelector(
                '.pagination-fetcher [data-pagination-fetcher-target]')
            : null

        if (!newItemsContainer || !newItemsContainer.children)
            return null

        if (newTarget)
            this.render(newTarget)

        Array.from(newItemsContainer.children)
            .forEach((node) => {
                if (href)
                    node.setAttribute('data-pagination-fetcher-page-href', href)

                node.classList.add('onload')

                const images = Array
                    .from(node.querySelectorAll('img[data-object-fit]'))

                images
                    .forEach(img => {
                        img.classList.add('onload')
                        img.addEventListener('load', () => {
                            img.classList.remove('onload')
                            window.objectFitPolyfill()
                        }, false)
                    })

                this.els.itemsContainer
                    .appendChild(node)

                setTimeout(() => node.classList.remove('onload'), 10)
            })

        return true
    }

    setCtaState (state = 'reset') {
        if (!this.els.cta)
            return

        if (state === 'reset') {
            this.els.cta.classList.remove('loading')
            this.els.cta.classList.remove('done')
            this.els.cta.classList.remove('error')
        }

        if (state === 'loading') {
            this.els.cta.classList.remove('done')
            this.els.cta.classList.remove('error')
            this.els.cta.classList.add('loading')
        }

        if (state === 'done') {
            this.els.cta.classList.remove('loading')
            this.els.cta.classList.remove('error')
            this.els.cta.classList.add('done')
        }

        if (state === 'error') {
            this.els.cta.classList.remove('loading')
            this.els.cta.classList.remove('done')
            this.els.cta.classList.add('error')
        }
    }

    render (target) {
        const pagination = this.el.querySelector('.pagination')

        if (!pagination)
            return

        const paginationFetcherTarget = target
            || pagination.querySelector('[data-pagination-fetcher-target]')

        if (!paginationFetcherTarget)
            return

        this.els.target = paginationFetcherTarget.cloneNode(true)

        pagination.innerHTML = ''
        pagination.appendChild(this.els.target)

        this.setCtaState(this.els.target.classList.contains('done')
                || !this.els.target.href
            ? 'done'
            : 'reset')
    }
}
