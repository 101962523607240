//* ---------------------------------------
//  Globally Scoped Vendors

import 'node_modules/html5shiv'
import 'node_modules/respond.js/dest/respond.src'
import 'node_modules/polyfill-queryselector'
import 'node_modules/console-shim'
import 'node_modules/core-js/client/shim'
import 'node_modules/details-element-polyfill'
import 'node_modules/gsap/src/uncompressed/TweenLite'
import 'node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin'
import 'node_modules/gsap/src/uncompressed/easing/EasePack'
import 'node_modules/objectFitPolyfill'
import 'node_modules/whatwg-fetch'

//* ---------------------------------------
//  Top Level Imports

import Component from './lib/component'
import { Components } from './components/index'
import Scroller from './lib/scroller'

//* ---------------------------------------
//  Create MountUnionApp

const MountUnionApp = new Component({
    el: document.body,
    Components: Components
})

//* ---------------------------------------
//  Detect Features like Modernizr

MountUnionApp
    .addFeatureTest({
        csspointerevents: () =>
            MountUnionApp.detectCSSProp('pointer-events'),
        csstouchaction: () =>
            MountUnionApp.detectCSSProp('touch-action'),
        'overlay-scrollbars': () => {
            const inner = document.createElement('p')
            const outer = document.createElement('div')

            inner.style.width = '100%'
            inner.style.height = '200px'

            outer.style.position = 'absolute'
            outer.style.top = '-150px'
            outer.style.left = '-200px'
            outer.style.width = '200px'
            outer.style.height = '150px'
            outer.style.visibility = 'hidden'
            outer.style.overflow = 'scroll'

            outer.appendChild(inner)
            document.body.appendChild(outer)

            const isOverlay = outer.offsetWidth - inner.clientWidth === 0
            document.body.removeChild(outer)

            return isOverlay
        }
    })
    .addFeaturesToClassName()

//* ---------------------------------------
//  Log MountUnionApp

console.groupCollapsed('MountUnionApp')
console.log(MountUnionApp)
console.groupEnd()

//* ---------------------------------------
//  MountUnionApp on Render

MountUnionApp
    .on('render', () => Object.keys(MountUnionApp.Components)
        .filter(key => document.querySelector(key))
        .forEach(key => Array.from(document
            .querySelectorAll(`${key}:not([data-rendered])`))
            .forEach(el => {
                console.groupCollapsed(MountUnionApp.Components[key].name)

                el.setAttribute('data-rendered', true)

                if (!MountUnionApp.Scroller
                        && ('scrollListener' in el.dataset
                            || ('scrollListener'
                                in MountUnionApp.Components[key]
                                && MountUnionApp.Components[key]
                                    .scrollListener())))
                    MountUnionApp.Scroller = new Scroller()

                const Component = new MountUnionApp.Components[key]({
                    el: el,
                    els: {},
                    props: el.dataset,
                    App: MountUnionApp
                })

                window.objectFitPolyfill()

                console.log(Component)
                console.groupEnd()
            })))

//* ---------------------------------------
//  MountUnionApp Init

MountUnionApp.emit('render')
