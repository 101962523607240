import Component from '../../lib/component.js'

export default class TablinksComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            toggle: this.el.querySelector('a.tabs-links-control')
        }

        this.onToggleClick = this.onToggleClick.bind(this)
        this.onToggleKeydown = this.onToggleKeydown.bind(this)

        this.render()
        this.listen()
    }

    listen () {
        if (!this.els.toggle)
            return

        this.els.toggle.addEventListener('click', this.onToggleClick)
        this.els.toggle.addEventListener('keydown', this.onToggleKeydown)
    }

    onToggleClick (e) {
        this.els.toggle.setAttribute('aria-expanded',
            this.els.toggle.getAttribute('aria-expanded') !== 'true')

        this.setToggleLabel()
    }

    onToggleKeydown (e) {
        if (e.keyCode !== 32
                && !(e.keyIdentifier || e.key || '').match(/^(enter)$/i))
            return

        this.onToggleClick(e)
    }

    setToggleLabel () {
        if (!this.els.toggle)
            return

        this.els.toggle.setAttribute('aria-label',
            `${this.els.toggle.getAttribute('aria-expanded') !== 'true'
                ? 'Show'
                : 'Hide'} ${this.els.toggle.innerText}`)
    }

    renderToggle () {
        if (!this.els.toggle)
            return

        this.els.toggle.setAttribute('role', 'button')
        this.els.toggle.setAttribute('tabindex', 0)

        const removeDeprecatedIcon = (type) => this.els.toggle
            .querySelector(`.tabs-links-control-${type}`)
            ? this.els.toggle
                .querySelector(`.tabs-links-control-${type}`)
                .remove()
            : null

        removeDeprecatedIcon('open')
        removeDeprecatedIcon('close')

        if (!this.els.toggle.innerText.length)
            this.els.toggle.innerText = 'Related Links'

        this.setToggleLabel()
    }

    render () {
        const current = this.el.querySelector('li[aria-current="page"] a')

        if (current) {
            current.parentNode.removeAttribute('aria-current')
            current.setAttribute('aria-current', 'page')
        }

        const checkbox = this.el
            .querySelector('input[type=checkbox]')
        const isExpanded = Boolean(checkbox && checkbox.checked)

        const label = checkbox
            ? this.el.querySelector(`[for="${checkbox.id}"]`)
            : null

        const list = this.el.querySelector('ul')
        const listId = list.id || `tabs-links-${this.generateGUID()}`

        if (list)
            list.id = listId

        const beforeRenderToggle = () => {
            if (checkbox)
                checkbox.remove()

            if (label)
                label.remove()

            if (!list)
                return this.renderToggle()

            this.els.toggle.setAttribute('aria-controls', listId)
            this.els.toggle.setAttribute('aria-expanded', isExpanded)

            this.renderToggle()
        }

        if (this.els.toggle
                && (this.els.toggle.getAttribute('aria-controls') === listId))
            return beforeRenderToggle()

        if (label) {
            label.removeAttribute('for')

            const tmp = document.createElement('a')
            const tmpHTML = label.outerHTML
                .replace(/(<\/*)(label)(?=>)*/g, '$1a')

            label.parentNode
                .insertBefore(tmp, label)

            tmp.outerHTML = tmpHTML
        }

        this.els.toggle = this.el.querySelector('.tabs-links-control')
        beforeRenderToggle()
    }
}
