import Component from '../../lib/component.js'

export default class SelectComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            select: this.el.querySelector('select'),
            selectBtn: null,
            selectContainer: null,
            optionsList: null
        }

        if (!this.els.select
                || this.els.select.multiple
                || this.getFeatures().touch)
            return

        this.onDocumentClick = this.onDocumentClick.bind(this)
        this.onLabelKeydown = this.onLabelKeydown.bind(this)
        this.onLabelClick = this.onLabelClick.bind(this)

        this.onListboxKeydown = this.onListboxKeydown.bind(this)
        this.onListboxClick = this.onListboxClick.bind(this)

        this.render()
        this.renderSelectLabel()
        this.renderOptionsList()

        this.listen()
    }

    listen () {
        if (!this.els.select)
            return

        this.els.selectBtn.onclick = this.onLabelClick
        this.els.selectBtn.onkeydown = this.onLabelKeydown

        this.els.optionsList.onkeydown = this.onListboxKeydown
        this.els.optionsList.onclick = this.onListboxClick

        document.body
            .addEventListener('click', this.onDocumentClick, false)

        this.observer = new window
            .MutationObserver(mutations => {
                this.renderOptionsList()
            })

        this.observer.observe(this.els.select, { childList: true })
    }

    onLabelClick (e) {
        if (this.els.select.disabled)
            return this.el.classList.add('disabled')

        this.el.classList.remove('disabled')
        this.el.classList.toggle('active')

        if (!this.el.classList.contains('active'))
            return

        e.preventDefault()
        e.stopPropagation()

        this.els.optionsList.focus()

        if ((e.keyIdentifier || e.key || '').match(/down/i))
            this.setSelectedOption()
    }

    onLabelKeydown (e) {
        if (e.which === 27) {
            this.el.classList.remove('active')
            return this.els.selectBtn.focus()
        }

        const key = (e.keyIdentifier || e.key || '')

        if (e.keyCode === 27
                || key.match(/enter/i)
                || (key.match(/(down|up)/i)
                    && !this.el.classList.contains('active')))
            this.onLabelClick(e)
    }

    onListboxClick (e) {
        this.setSelectedOption(e.target)
        this.el.classList.remove('active')
        return this.els.selectBtn.focus()
    }

    onListboxKeydown (e) {
        const key = (e.keyIdentifier || e.key || '')

        if (e.which === 27 || key.match(/enter/i)) {
            this.el.classList.remove('active')
            return this.els.selectBtn.focus()
        }

        const items = this.getOptionsListItems()

        if (!items.length)
            return

        const selectOption = (el) => {
            e.preventDefault()
            e.stopPropagation()

            this.setSelectedOption(el)
        }

        if (key.match(/(home|end)/i))
            return selectOption(
                key.match(/home/i)
                    ? items[0]
                    : items[items.length - 1])

        const selected = this.els.optionsList
            .querySelector('[aria-selected="true"][role=option]')
        const selectedIdx = items.indexOf(selected)

        if (key.match(/(up|down)/i)) {
            if (selectedIdx < 0)
                return

            const next = key.match(/down/i)
                ? items[selectedIdx + 1]
                : items[selectedIdx - 1]

            if (next)
                return selectOption(next)
        }

        if (!key.match(/(\d|\w)/))
            return

        const jumpTo = items.slice(selectedIdx + 1)
            .find(el => el.innerText.toLowerCase().charAt(0)
                === key.toLowerCase())

        if (jumpTo)
            selectOption(jumpTo)
    }

    onDocumentClick (e) {
        const eventPath = e.path || []

        // Build path array for other browsers
        if (!eventPath.length) {
            let node = e.target

            while (node !== document.body) {
                eventPath.push(node)
                node = node.parentNode
            }
        }

        if (eventPath.indexOf(this.el) < 0)
            this.el.classList.remove('active')
    }

    setSelectedText (str) {
        this.els.selectBtn.querySelector('span').innerText
            = (str || (this.getSelectedOptionDefault() || {}).innerText || '')
                .trim()
    }

    getSelectedOptionDefault () {
        return this.els.optionsList.querySelector('[aria-selected="true"]')
            || this.getOptionsListItems()
                .filter(el => el.getAttribute('aria-disabled') !== 'true')[0]
    }

    setSelectedOption (el) {
        if (!this.getOptionsListItems().length)
            return

        el = el || this.getSelectedOptionDefault()

        if (!el || el.getAttribute('aria-disabled') === 'true')
            return

        if (el.getAttribute('aria-selected') !== 'true')
            this.getOptionsListItems()
                .filter(node => node.getAttribute('aria-selected'))
                .forEach(node => node.removeAttribute('aria-selected'))

        const option = this.els.select
            .querySelector(`option[data-id="${el.id}"]`)

        if (!option)
            return

        el.setAttribute('aria-selected', true)

        this.els.optionsList
            .setAttribute('aria-activedescendant', el.id)

        this.els.optionsList.scrollTop = el.offsetTop
        this.setSelectedText()

        if (option === this.getSelectedOption())
            return

        this.els.select.selectedIndex = Array.from(this.els.select
            .querySelectorAll('option'))
            .indexOf(option)

        this.els.select.value = option.value

        const evt = this.getBrowser('name') === 'ie'
            ? document.createEvent('Event')
            : new window.Event('change')

        if (this.getBrowser('name') === 'ie')
            evt.initEvent('change', true, true)

        this.els.select.dispatchEvent(evt)
    }

    getSelectedOption () {
        return this.els.select.selectedIndex < 0
            ? null
            : this.els.select
                .querySelectorAll('option')[this.els.select.selectedIndex]
    }

    getOptions () {
        return Array.from(this.el.querySelectorAll('select option'))
    }

    getOptionsListItems () {
        return Array
            .from(this.els.optionsList.querySelectorAll('[role="option"]'))
            .filter(el => el.getAttribute('aria-disabled') !== 'true')
    }

    renderSelectLabel () {
        this.els.selectBtn = document.createElement('a')

        this.els.selectBtn.id = `${this.els.select.id}-btn`
        this.els.selectBtn.className = 'select-btn'
        this.els.selectBtn.innerHTML = '<span></span>'

        this.els.selectBtn.setAttribute('role', 'button')
        this.els.selectBtn.setAttribute('tabindex', 0)
        this.els.selectBtn.setAttribute('aria-haspopup', 'listbox')
        this.els.selectBtn.setAttribute('aria-labelledby',
            this.els.selectBtn.id)

        this.els.selectContainer.appendChild(this.els.selectBtn)
    }

    renderAriaLabel (el) {
        if (!el || !el.tagName.match(/label/i))
            return el

        el.id = el.id || `${this.els.select.id}-label`
        el.removeAttribute('for')
        el.classList.add('select-label')
        el.outerHTML = el.outerHTML
            .replace(/(<\/*)(label)(?=>)*/g, '$1span')

        return el
    }

    renderOptionsList () {
        if (!this.els.optionsList) {
            this.els.optionsList = document.createElement('ul')
            this.els.optionsList.className = 'select-options'
            this.els.optionsList.id = `${this.els.select.id}-listbox`
            this.els.optionsList.setAttribute('role', 'listbox')
            this.els.optionsList.setAttribute('tabindex', -1)

            const labelledby = Array.from(this.el
                .querySelectorAll(`label[for="${this.els.select.id}"]`))
                .map(el => this.renderAriaLabel(el))
                .map(el => el.id)
                .filter(str => str)
                .concat((this.els.select.getAttribute('aria-labelledby') || '')
                    .split(/\s+/)
                    .filter(str => str.length)
                    .map(id => this.el.querySelector(`#${id}`))
                    .filter(el => el))
                .join(' ')

            if (labelledby) {
                this.els.optionsList
                    .setAttribute('aria-labelledby', labelledby)

                this.els.selectBtn
                    .setAttribute('aria-labelledby', (`${labelledby} ${
                        this.els.selectBtn
                            .getAttribute('aria-labelledby')}`).trim())
            }
        }

        this.els.optionsList.innerHTML = ''

        this.getOptions()
            .forEach((option, idx) => {
                if (idx === 0
                        && option.selected
                        && option.disabled
                        && !option.value)
                    return this.setSelectedText(option.innerHTML)

                const id = `${this.els.select.id}-option-${idx}`
                option.setAttribute('data-id', id)

                this.els.optionsList.innerHTML += `<li ${
                    option.selected ? 'aria-selected="true" ' : ''}${
                    option.disabled ? 'aria-disabled="true" ' : ''}value="${
                    option.value}" role="option" id="${id}">${
                    option.innerHTML}</li>
                `
            })

        this.els.selectContainer.appendChild(this.els.optionsList)

        if (!this.els.selectBtn.innerText.length)
            this.setSelectedOption()
    }

    render () {
        if (!this.els.select)
            return

        this.els.select.id = this.els.select.id
            || `select-${this.generateGUID()}`

        this.els.select.setAttribute('aria-hidden', true)
        this.els.select.setAttribute('tabindex', -1)

        this.els.selectContainer = document.createElement('div')
        this.els.selectContainer.classList.add('select-container')

        this.el.appendChild(this.els.selectContainer)
    }
}
