import Component from '../../lib/component.js'

export default class SwitchComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            handle: this.el.querySelector('.switch-handle'),
            input: null
        }

        // this.mouse.transformX matches switch-handle's on/off css transformX
        this.mouse = { down: null, move: null, transformX: { min: 0, max: 20 } }
        this.touches = { start: null, end: null, threshold: 10 }

        this.onInputChange = this.onInputChange.bind(this)
        this.onClick = this.onClick.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.onMouseEnd = this.onMouseEnd.bind(this)
        this.onTouchStart = this.onTouchStart.bind(this)
        this.onTouchEnd = this.onTouchEnd.bind(this)
        this.onChange = this.onChange.bind(this)

        this.render()

        this.state.set({
            'on': this.els.input.checked,
            'disabled': this.els.input.disabled
        })

        this.listen()
    }

    listen () {
        this.state.on('update', this.onChange)

        // On hidden :checked change, can be trigged by label click
        this.els.input
            .addEventListener('change', this.onInputChange, false)

        // On click, regardless of device
        this.el
            .addEventListener('click', this.onClick, false)

        // Listen for keydown events
        this.el
            .addEventListener('keydown', this.onKeyDown, false)

        // If touch, listen for touchend for drag
        if (this.getFeatures().touch) {
            this.el
                .addEventListener('touchstart', this.onTouchStart,
                    this.passiveSupported
                        ? { passive: true }
                        : false)
            this.el
                .addEventListener('touchend', this.onTouchEnd, false)

            return
        }

        // If mouse, listen to move for drag
        this.els.handle
            .addEventListener('mousedown', this.onMouseDown, false)

        // If mouse, listen to move for drag
        this.els.handle
            .addEventListener('mousemove', this.onMouseMove, false)

        // If mouse, listen to leave or up on drag
        this.els.handle
            .addEventListener('mouseleave', this.onMouseEnd, false)

        this.els.handle
            .addEventListener('mouseup', this.onMouseEnd, false)
    }

    onChange (e) {
        if (!Object.keys(e.data.previousData).length)
            return

        this.el.setAttribute('aria-checked', e.data.currentData.on)
        this.els.input.value = e.data.currentData.on
        this.els.input.checked = e.data.currentData.on

        this.el.setAttribute('aria-disabled', e.data.currentData.disabled)
        this.els.input.disabled = e.data.currentData.disabled

        const evt = this.getBrowser('name') === 'ie'
            ? document.createEvent('Event')
            : new window.Event('change')

        if (this.getBrowser('name') === 'ie')
            evt.initEvent('change', true, true)

        this.el.dispatchEvent(evt)
    }

    onInputChange (e) {
        if (this.state.get('on') !== this.els.input.checked)
            this.state.set('on', this.els.input.checked)
    }

    onKeyDown (e) {
        if (e.key.match(/enter/i)
                && document.activeElement === this.el
                && !this.state.get('disabled'))
            this.els.input.click()
    }

    onClick (e) {
        if (this.els.handle.getAttribute('style'))
            return this.els.handle.removeAttribute('style')

        if (!this.state.get('disabled'))
            this.els.input.click()
    }

    onMouseDown (e) {
        if (this.state.get('disabled'))
            return

        this.mouse.down = e.clientX
    }

    onMouseMove (e) {
        const switchBounds = this.getSwitchBounds()
        const prevClientX = this.mouse.move

        if (this.state.get('disabled')
                || !this.mouse.down
                || e.clientX < switchBounds.left
                || e.clientX > switchBounds.right)
            return

        if (!this.els.handle.getAttribute('style'))
            this.els.handle.style.transition = 'none'

        this.mouse.move = e.clientX

        if (!prevClientX) {
            this.els.handle.style.transform = `translateX(${
                this.getHandleTranslateX()}px)`
            return
        }

        if (prevClientX === e.clientX)
            return

        const handleX = (e.clientX - prevClientX) + this.getHandleTranslateX()

        if (handleX >= this.mouse.transformX.min
                && handleX <= this.mouse.transformX.max)
            this.els.handle.style.transform = `translateX(${handleX}px`
    }

    onMouseEnd (e) {
        if (this.state.get('disabled')
                || !this.mouse.down)
            return

        this.state.set('on', this.getHandleTranslateX()
            >= (this.mouse.transformX.max / 2))

        this.mouse.down = null
        this.mouse.move = null
    }

    onTouchStart (e) {
        if (this.state.get('disabled')
                || !e.touches
                || !e.touches[0])
            return

        this.touches.start = e.touches[0]
    }

    onTouchEnd (e) {
        if (this.state.get('disabled')
                || !this.touches.start
                || !this.touches.start.clientX
                || !e.changedTouches
                || !e.changedTouches[0])
            return

        this.touches.end = e.changedTouches[0]

        const startX = this.touches.start.clientX
        const endX = this.touches.end.clientX
        const diffX = startX - endX

        if (diffX >= this.touches.threshold
                || (diffX < 0
                    && (diffX * -1) > this.touches.threshold))
            this.state.set('on', diffX < 0)

        this.touches.start = null
        this.touches.end = null
    }

    getSwitchBounds () {
        const originalBounds = this.el.getBoundingClientRect()
        const bounds = {}
        const padding = Number(
            ((window.getComputedStyle(this.el) || {}).padding || '0')
                .replace(/\D/g, ''))

        bounds.left = originalBounds.left + padding
        bounds.right = originalBounds.right - padding
        bounds.top = originalBounds.top + padding
        bounds.bottom = originalBounds.bottom - padding

        const obj = {}

        Object.keys(originalBounds)
            .forEach(key => {
                obj[key] = originalBounds[key]
            })

        return Object.assign(obj, bounds)
    }

    getHandleTranslateX () {
        if (!this.els.handle.style.transform)
            return this.state.get('on')
                ? this.mouse.transformX.max
                : this.mouse.transformX.min

        return Number(this.els.handle.style.transform.replace(/\D/g, ''))
    }

    on () {
        if (this.state.get('disabled'))
            return

        this.state.set('on', true)
    }

    off () {
        if (this.state.get('disabled'))
            return

        this.state.set('on', false)
    }

    toggle () {
        return this.state.get('on')
            ? this.off()
            : this.on()
    }

    disable () {
        this.state.set('disabled', true)
    }

    enable () {
        this.state.set('disabled', false)
    }

    render () {
        if (!this.els.handle) {
            this.els.handle = document.createElement('div')
            this.els.handle.className = 'switch-handle'
            this.el.appendChild(this.els.handle)
        }

        this.els.input = document.createElement('input')
        this.els.input.setAttribute('tabindex', '-1')
        this.els.input.checked = this.el.getAttribute('aria-checked')
            && this.el.getAttribute('aria-checked') === 'true'
        this.els.input.disabled = this.el.getAttribute('aria-disabled')
            && this.el.getAttribute('aria-disabled') === 'true'

        this.els.input.id = this.el.id || `switch-${this.generateGUID()}`

        if (this.props.name)
            this.els.input.name = this.props.name

        this.els.input.type = 'checkbox'
        this.els.input.value = this.els.input.checked

        this.el.setAttribute('role', 'switch')
        this.el.setAttribute('aria-checked', this.els.input.checked)
        this.el.setAttribute('aria-disabled', this.els.input.disabled)

        // Add tabindex if doesn't exist
        if (!this.el.getAttribute('tabindex'))
            this.el.setAttribute('tabindex', '0')

        this.el.removeAttribute('id')
        this.el.appendChild(this.els.input)
    }
}
