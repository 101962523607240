import Component from '../../lib/component.js'

export default class TabslistComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            tabs: Array.from(this.el.querySelectorAll('[role="tab"]')),
            panels: Array.from(this.el.querySelectorAll('[role="tabpanel"]'))
        }

        this.onTabsClick = this.onTabsClick.bind(this)
        this.onTabsKeydown = this.onTabsKeydown.bind(this)

        this.render()
        this.listen()

        this.emit('resize')
    }

    listen () {
        this.els.tabs
            .forEach(el => {
                el.addEventListener('click', this.onTabsClick, false)
                el.addEventListener('keydown', this.onTabsKeydown, false)
            })

        this.on('resize', this.onResize)
    }

    onResize (e) {
        if (!this.el.parentNode || !this.el.parentNode.parentNode)
            return

        const tablistContent = this.el
            .querySelector('.tablist-content')
        const card = this.el.parentNode.parentNode.classList.contains('card')
            ? this.el.parentNode.parentNode
            : null
        const cardThumb = this.el.parentNode
                && this.el.parentNode.previousElementSibling
                && this.el.parentNode.previousElementSibling.classList
                    .contains('card-thumb')
            ? this.el.parentNode.previousElementSibling
            : null

        if (tablistContent)
            tablistContent.style.minHeight = '0px'

        if (!tablistContent
                || !card
                || !cardThumb
                || window.getComputedStyle(cardThumb).display === 'none')
            return

        let minHeight = 0

        Array.from(this.el.querySelectorAll('[role="tabpanel"]'))
            .forEach(node => {
                if (node.clientHeight > minHeight)
                    minHeight = node.clientHeight
            })

        tablistContent.style.minHeight = `${minHeight}px`
    }

    onTabsClick (e) {
        const tab = e.target
        const panel = this.el.querySelector(`#${
            tab.getAttribute('aria-controls')}`)

        if (!tab || !panel)
            return

        if (this.getActiveTab()
                && this.getActiveTab().id !== tab.id)
            this.getActiveTab()
                .setAttribute('aria-selected', false)

        if (this.getActivePanel()
                && this.getActivePanel()
                    .getAttribute('aria-labelledby') !== tab.id)
            this.getActivePanel()
                .setAttribute('aria-hidden', true)
        else
            Array.from(this.el.querySelectorAll('[role=tabpanel]'))
                .forEach(node => node.setAttribute('aria-hidden',
                    node.getAttribute('aria-labelledby') !== tab.id))

        tab.setAttribute('aria-selected', true)
        panel.setAttribute('aria-hidden', false)

        if (tab.getAttribute('data-value'))
            this.el.setAttribute('data-active-value',
                tab.getAttribute('data-value'))

        const evt = this.getBrowser('name') === 'ie'
            ? document.createEvent('Event')
            : new window.Event('change')

        if (this.getBrowser('name') === 'ie')
            evt.initEvent('change', true, true)

        this.el.dispatchEvent(evt)
    }

    onTabsKeydown (e) {
        if (e.keyCode !== 32
                && !(e.keyIdentifier || e.key || '').match(/^(enter)$/i))
            return

        this.onTabsClick(e)
    }

    getActiveTab () {
        return this.el
            .querySelector('[role="tab"][aria-selected="true"]')
    }

    getActivePanel (label) {
        return this.el
            .querySelector('[role="tabpanel"][aria-hidden="false"]')
    }

    render () {
        this.els.tabs
            .filter(node => node.parentNode
                && node.parentNode.tagName.match(/label/i)
                && node.parentNode.parentNode)
            .forEach((node) => {
                const panel = this.el.querySelector(
                    node.getAttribute('aria-controls'))
                const label = node.parentNode
                const id = label.getAttribute('for')
                const radio = this.el.querySelector(`#${id}`)

                if (label.classList.contains('full-width'))
                    node.classList.add('full-width')

                label.parentNode
                    .insertBefore(node, label)

                label.remove()

                if (radio
                        && radio.value
                        && radio.value !== 'on')
                    node.setAttribute('data-value', radio.value)

                if (radio)
                    radio.remove()

                node.id = node.id || id

                if (panel)
                    panel.setAttribute('aria-labelledby', node.id)

                node.setAttribute('tabindex', 0)
            })
    }
}
