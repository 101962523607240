import Baobab from 'node_modules/baobab'

export default class Scroller {
    constructor (opts = { el: window }) {
        this.el = opts.el
        this.ticking = false
        this.state = new Baobab({
            scrollX: 0,
            scrollY: 0,
            directionY: null,
            directionX: null
        })

        this.passiveSupported = false

        try {
            const testOpts = Object.defineProperty({}, 'passive', {
                get: () => {
                    this.passiveSupported = true
                }
            })

            window.addEventListener('test', testOpts, testOpts)
            window.removeEventListener('test', testOpts, testOpts)
        }
        catch (err) {
            console.warn('Passive Listening Not Supported')
        }

        if (typeof this.el === 'string'
                && !document.querySelector(this.el))
            return this.observe(this.el)
        else if (typeof this.el === 'string')
            this.el = document.querySelector(this.el)

        return this.listen()
    }

    observe () {
        const observer = new window.MutationObserver(() => {
            if (typeof this.el === 'string'
                    && document.querySelector(this.el)) {
                this.el = document.querySelector(this.el)
                observer.disconnect()
                this.listen()
            }
        })

        observer.observe(document.documentElement, {
            childList: true,
            subtree: true
        })

        return this
    }

    listen () {
        this.el
            .addEventListener('scroll', () => this.onScroll())

        if ('ontouchstart' in document.documentElement)
            this.el
                .addEventListener('touchstart', () => this.onScroll())

        this.onScroll()
        return this
    }

    onScroll () {
        if (this.ticking)
            return

        window
            .requestAnimationFrame(() => {
                this.ticking = false
            })

        const scrollX = this.getScroll('scrollX')
        const scrollY = this.getScroll('scrollY')

        const getScrolledDirection = (axis) => {
            const scrollAxis = `scroll${axis}`
            const directionAxis = `direction${axis}`

            let scrollDiff = this.state.get(scrollAxis)
                - this.getScroll(scrollAxis)

            if (scrollDiff < 0)
                scrollDiff = scrollDiff * -1

            const prevScroll = axis === 'Y'
                ? scrollY
                : scrollX

            if (scrollDiff < 0.5)
                return this.state.get(directionAxis)
                        && prevScroll !== this.state.get(scrollAxis)
                    ? this.state.get(directionAxis)
                    : null

            return prevScroll < this.state.get(scrollAxis)
                ? axis === 'Y'
                    ? 'up'
                    : 'left'
                : axis === 'Y'
                    ? 'down'
                    : 'right'
        }

        this.state.set({
            scrollX: scrollX,
            scrollY: scrollY,
            directionX: getScrolledDirection('X'),
            directionY: getScrolledDirection('Y')
        })

        this.ticking = true
    }

    getScroll (dir) {
        // Scroll{Y,X} only on window
        if (this.el[dir])
            return this.el[dir]

        // Scroll{Y,X} fallback, only on window
        const pageOffset = dir === 'scrollY'
            ? this.el.pageYOffset
            : this.el.pageXOffset

        if (pageOffset)
            return pageOffset

        // Update dir to target overflow elements and/or legacy browsers
        dir = dir === 'scrollY'
            ? 'scrollTop'
            : 'scrollLeft'

        if (this.el[dir])
            return this.el[dir]

        // Legacy browsers only
        return ((document.compatMode || '') === 'CSS1Compat')
            ? document.documentElement[dir]
            : document.body[dir]
    }

    scrollTo (coordinates = {x: 0, y: 0}, time = 0.75) {
        coordinates.x = coordinates.x || 0
        coordinates.y = coordinates.y || 0

        return new Promise((resolve) => {
            if (!this.el)
                return resolve()

            // Fallback scroll
            if (!window.TweenLite || !window.ScrollToPlugin) {
                if (this.el === window)
                    window.scrollTo(coordinates.x, coordinates.y)
                else {
                    this.el.scrollLeft = coordinates.x
                    this.el.scrollTop = coordinates.y
                }

                return resolve()
            }

            // Animated scroll flag
            this.scrollingTo = true

            // Animated scroll
            window.TweenLite.to(this.el, time, {
                scrollTo: coordinates,
                ease: window.Power3.easeOut,
                onComplete: () => {
                    this.scrollingTo = false
                    this.onScroll()
                    resolve()
                }
            })
        })
    }
}
