import EventEmitter from 'node_modules/eventemitter3'

export default class Obj extends EventEmitter {
    constructor (opts) {
        super()

        if (opts)
            this.extend(opts)
    }

    extend (obj) {
        if (obj && typeof obj === 'object' && !Array.isArray(obj))
            Object.keys(obj)
                .forEach(opt => {
                    this[opt] = obj[opt]
                })

        return this
    }
}
