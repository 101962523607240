import Component from '../../lib/component.js'

export default class FactsComponent extends Component {
    static scrollListener () {
        return true
    }

    constructor (opts) {
        super(opts)

        if (!this.el || !this.App || !this.App.Scroller)
            return

        this.els = {
            itemInfo: Array.from(this.el.querySelectorAll('.facts-item-info'))
        }

        this.onScroll = this.onScroll.bind(this)

        this.render()
        this.onScroll()
        this.listen()
    }

    listen () {
        this.App.Scroller.state
            .on('update', this.onScroll)
    }

    onScroll (changed = this.App.Scroller.state.get()) {
        if (this.el.classList.contains('in-view'))
            return

        if (this.el.getBoundingClientRect().top <= window.innerHeight * 0.7)
            this.el.classList.add('in-view')
    }

    render () {
        this.els.itemInfo
            .forEach((node, idx) => {
                node.style.animationDelay = `${idx * 800}ms`
            })
    }
}
