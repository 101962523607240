import Component from '../../lib/component.js'
import Flickity from 'node_modules/flickity-as-nav-for'

export default class CarouselComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el || !this.getCarouselItems().length)
            return

        this.els = {
            slideshowToggle: this.el.querySelector('.carousel-slideshow-toggle')
        }

        const isCarouselNav = this.props.nav === 'true'
            && this.props.type === 'contain'

        if (isCarouselNav)
            this.renderCarouselNav()

        const isCarouselSlideshow = this.props.slideshow
            && this.props.type === 'contain'

        if (isCarouselSlideshow)
            this.renderCarouselSlideshowThumbs()

        this.carousel = new Flickity(this.el, {
            cellSelector: '.carousel-item',
            adaptiveHeight: isCarouselNav || isCarouselSlideshow,
            wrapAround: true,
            pageDots: true,
            contain: this.props.type === 'contain',
            prevNextButtons: !isCarouselNav
        })

        if (this.els.carouselNav) {
            this.carouselNav = new Flickity(this.els.carouselNav, {
                asNavFor: this.el,
                cellSelector: '.carousel-item',
                wrapAround: true
            })

            this.render(this.els.carouselNav)
        }

        this.onChange = this.onChange.bind(this)
        this.onTablistChange = this.onTablistChange.bind(this)

        this.render()
        this.onChange()
        this.listen()
    }

    listen () {
        this.carousel
            .on('change', this.onChange)

        const cardTablists = Array.from(this.el
            .querySelectorAll('[role="tablist"]'))

        cardTablists
            .forEach(node => node
                .addEventListener('change', this.onTablistChange, false))

        if (cardTablists.length)
            this.on('resize', () => this.onResize(10))

        if (!this.props.slideshow)
            return

        if (this.els.slideshowToggle)
            this.els.slideshowToggle.onclick = (e) => this.onSlideshowToggle(e)

        if (this.els.carouselSlideshowThumbs)
            Array.from(this.els.carouselSlideshowThumbs
                .querySelectorAll('a[data-index]'))
                .forEach(node => {
                    node.onclick = (e) => this.onSlideshowToggle(e)
                })
    }

    onChange (i) {
        this.getCarouselItems()
            .filter((node, idx) => idx !== i
                && node.querySelector('.media'))
            .forEach(node => Array.from(node.querySelectorAll('.media'))
                .forEach(el => {
                    const evt = this.getBrowser('name') === 'ie'
                        ? document.createEvent('Event')
                        : new window.Event('slidechange')

                    if (this.getBrowser('name') === 'ie')
                        evt.initEvent('slidechange', true, true)

                    el.dispatchEvent(evt)
                }))

        if (this.getFeatures().touch)
            return

        // Fix for time rail not being draggable on desktop
        const isMedia = this.el
            .querySelector('.carousel-item.is-selected .media')
        const isDraggable = this.carousel.options.draggable

        if ((!isMedia && !isDraggable)
                || (isMedia && isDraggable))
            this.toggleDraggable()
    }

    onTablistChange (e) {
        const activeTablistContent = e.target
            .querySelector('[role="tablist"] .tablist-content')

        let isResize = false

        const getTablistContents = () => Array.from(this.el
            .querySelectorAll('[role="tablist"] .tablist-content'))

        if (getTablistContents()
            .filter(node => node
                .querySelector('[role="tabpanel"][aria-hidden="false"]'))
            .filter(node => node.clientHeight > node.querySelector(
                '[role="tabpanel"][aria-hidden="false"]').clientHeight).length
                    === getTablistContents().length)
            isResize = true

        if (isResize) {
            let viewportHeight = 0

            this.el.classList.add('sizing')
            this.el.querySelectorAll('.carousel-item')
                .forEach(node => {
                    if (node.clientHeight > viewportHeight)
                        viewportHeight = node.clientHeight
                })

            this.el.querySelector('.flickity-viewport').style.height = `${
                viewportHeight}px`

            return this.el.classList.remove('sizing')
        }

        getTablistContents()
            .filter(node => node !== activeTablistContent)
            .forEach(node => {
                if (isResize)
                    return false

                if (activeTablistContent.clientHeight > node.clientHeight)
                    isResize = true
            })

        if (isResize)
            this.onResize()
    }

    onResize (delay = 0) {
        if (!delay)
            return this.carousel.resize()

        this.el.classList.add('sizing')

        setTimeout(() => {
            this.carousel.resize()
            this.el.classList.remove('sizing')
        }, delay)
    }

    onSlideshowToggle (e) {
        this.el.classList
            .toggle('carousel-slideshow-thumbs-active')

        if (this.el.classList.contains('carousel-slideshow-thumbs-active'))
            this.onChange()

        const idx = e.target.getAttribute('data-index')
            || e.target.parentNode.getAttribute('data-index')

        if (!idx)
            return window.objectFitPolyfill()

        this.carousel.select(Number(idx))
    }

    toggleDraggable () {
        this.carousel.options.draggable = !this.carousel.options.draggable
        this.carousel.updateDraggable()
    }

    getCarouselItems () {
        return Array.from(this.el.querySelectorAll('.carousel-item'))
    }

    renderCarouselSlideshowThumbs () {
        this.els.carouselSlideshowThumbs = document.createElement('div')
        this.els.carouselSlideshowThumbs
            .classList.add('carousel-slideshow-thumbs')
        this.els.carouselSlideshowThumbs
            .classList.add('row')
        this.els.carouselSlideshowThumbs
            .classList.add('margin-3-b-neg')

        this.getCarouselItems()
            .forEach((node, idx) => {
                const img = node.querySelector('figure img')
                const thumb = node.getAttribute('data-thumb')

                if (!img && !thumb)
                    return

                this.els.carouselSlideshowThumbs.innerHTML += `
                    <div class="col-4 margin-3-b">
                        <a data-index="${idx}" class="ratio-square">
                            ${img ? img.outerHTML : `<img
                                src="${thumb}"
                                alt="${node.getAttribute('data-thumb-alt')
                                    || `Item ${idx + 1}`}"
                                data-object-fit="cover"
                            >`}
                        </a>
                    </div>
                `
            })

        this.el.parentNode.appendChild(this.els.carouselSlideshowThumbs)
    }

    renderCarouselNav () {
        this.els.carouselNav = this.el.cloneNode(true)
        this.els.carouselNav.classList.add('carousel-nav')
        this.el.classList.add('carousel-main')

        Array.from(this.els.carouselNav.querySelectorAll('.carousel-item'))
            .forEach((node) => {
                const title = node.getAttribute('data-title') || ''
                const subtitle = node.getAttribute('data-subtitle') || ''
                const img = node.getAttribute('data-thumb')
                    ? `<img src="${node.getAttribute('data-thumb')}" alt=${
                        title} data-object-fit="cover">`
                    : ''

                node.innerHTML = `
                    <div class="card pad-3-b" style="box-shadow:none;">
                        <div class="ratio-wide">${img}</div>
                        <div class="pad pad-0-t margin-t border-l">
                            <p class="heading-6 font-weight-normal dark-gray-2">${title}</p>
                            <p class="caption light-gray-5">${subtitle}</p>
                        </div>
                    </div>
                `
            })

        this.el.parentNode.appendChild(this.els.carouselNav)
        setTimeout(() => this.carouselNav.resize(), 1)
    }

    render (el = this.el) {
        Array.from(el.querySelectorAll('.flickity-prev-next-button'))
            .forEach((node) => {
                node.innerHTML = ''
                node.classList.add(node.classList.contains('previous')
                    ? 'mu-icon-chevron-left'
                    : 'mu-icon-chevron-right')
                node.classList.add('btn-round-lg')
                node.classList.add('white-bg')
            })

        setTimeout(() => this.carousel.resize(), 1)
    }
}
