import Tabbable from 'node_modules/tabbable'
import Component from '../../lib/component.js'

export default class CounselorMapComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            selects: Array.from(this.el.querySelectorAll('select'))
        }

        this.onBtnClick = this.onBtnClick.bind(this)
        this.onBtnKeydown = this.onBtnKeydown.bind(this)
        this.onSelectChange = this.onSelectChange.bind(this)

        this.render()
        this.listen()
    }

    listen () {
        this.getButtons()
            .forEach(el => {
                el.addEventListener('click', this.onBtnClick, false)
                el.addEventListener('keydown', this.onBtnKeydown, false)
            })

        this.els.selects
            .forEach(el => el
                .addEventListener('change', this.onSelectChange, false))
    }

    onBtnClick (e) {
        const btn = e.target.getAttribute('role') === 'button'
            ? e.target
            : e.target.parentNode
                    && e.target.parentNode.getAttribute('role') === 'button'
                ? e.target.parentNode
                : null

        const panel = btn && this.el
            .querySelector(`#${btn.getAttribute('aria-controls')}`)
            ? this.el
                .querySelector(`#${btn.getAttribute('aria-controls')}`)
            : null

        if (!btn || !panel)
            return

        this.getPanels()
            .forEach(el => {
                el.setAttribute('aria-hidden', el !== panel)
            })

        this.getButtons()
            .forEach(el => el.setAttribute('aria-expanded',
                el.getAttribute('aria-controls') === panel.id))

        if (!this.getActivePanel()
                || !e.target.classList.contains('btn-reveal'))
            return

        const els = Tabbable(this.getActivePanel())
            .filter(el => !el.classList.contains('btn-reveal'))

        if (els[0])
            els[0].focus()
    }

    onBtnKeydown (e) {
        if (e.keyCode !== 32
                && !(e.keyIdentifier || e.key || '').match(/^(enter)$/i))
            return

        this.onBtnClick(e)
    }

    onSelectChange (e) {
        if (!this.getActivePanel())
            return

        let isSecondarySelectActive = false

        Array.from(this.getActivePanel()
            .querySelectorAll('.counselor-map-secondary-select'))
            .forEach(node => {
                const isActive = node.classList.contains(e.target.value)
                const isReset = !isActive && !node.querySelector(
                    `option[value="${e.target.value}"]`)
                const select = node.querySelector('select')

                if (isActive)
                    isSecondarySelectActive = isActive

                node.style.display = isReset
                    ? 'none'
                    : 'block'

                if (!isReset || !select || !select.value)
                    return

                select.selectedIndex = 0

                const selectBtnText = select.parentNode
                    .querySelector('.select-btn span')

                if (!selectBtnText)
                    return

                const placeholderOption = select.querySelector('option')
                        && select.querySelector('option').disabled
                        && !select.querySelector('option').value
                    ? select.querySelector('option')
                    : null

                selectBtnText.innerText = placeholderOption
                    ? placeholderOption.innerText
                    : ''
            })

        this.setActiveCounselorsFromValue(isSecondarySelectActive
            ? false
            : e.target.value)
    }

    getButtons () {
        return Array.from(this.el
            .querySelectorAll('[role="button"][aria-controls]'))
            .filter(el => this.el
                .querySelector(`#${el.getAttribute('aria-controls')}`))
    }

    getPanels () {
        return Array.from(this.el
            .querySelectorAll('.counselor-map-panel'))
    }

    getActivePanel () {
        return this.el
            .querySelector('.counselor-map-panel[aria-hidden="false"]')
    }

    getOptionTextFromValue (val) {
        if (!val)
            return null

        return (this.el
            .querySelector(`option[value="${val}"]`) || { innerText: null })
            .innerText
    }

    setActiveCounselorsFromValue (val) {
        if (!this.getActivePanel())
            return

        if (this.getActivePanel().querySelector('.option-text')
                && this.getOptionTextFromValue(val))
            this.getActivePanel()
                .querySelector('.option-text')
                .innerText = this.getOptionTextFromValue(val)

        Array.from(this.getActivePanel()
            .querySelectorAll('.counselor-map-counselors li'))
            .forEach(node => !val || !node.classList.contains(val)
                ? node.removeAttribute('data-active')
                : node.setAttribute('data-active', true))

        return !val
            ? this.getActivePanel()
                .removeAttribute('data-active-value')
            : this.getActivePanel()
                .setAttribute('data-active-value', val)
    }

    renderAccessibilePanels () {
        const tablist = this.el.querySelector('[role="tablist"]')
        const tabcontent = this.el.querySelector('.tablist-content')

        if (tablist)
            tablist.removeAttribute('role')

        if (tabcontent) {
            tabcontent.classList.remove('tablist-content')
            tabcontent.classList.add('row')
        }

        Array.from(this.el.querySelectorAll('[role="tabpanel"]'))
            .forEach(node => {
                if (node.previousElementSibling
                        && node.previousElementSibling.type === 'radio')
                    node.previousElementSibling.remove()

                node.classList.remove('row')
                node.classList.add('counselor-map-panel')
                node.classList.add('col-auto')
                node.classList.add('pad-0')

                node.removeAttribute('role')
                node.removeAttribute('aria-hidden')
                node.removeAttribute('aria-label')
                node.removeAttribute('aria-labelledby')

                Array
                    .from(node.children)
                    .filter(el => el.classList.contains('col'))
                    .forEach(el => {
                        if (el.querySelector('.counselor-map-btn'))
                            return el.classList.add('counselor-map-cta')

                        el.classList.add('counselor-map-step')

                        if (el.querySelector('.select'))
                            return el.classList.add('counselor-map-select')

                        const counselorsList = el
                            .querySelector('.counselor-map-counselors')

                        if (!counselorsList)
                            return

                        el.classList.add('counselor-map-counselors')

                        counselorsList.classList
                            .remove('counselor-map-counselors')

                        counselorsList.classList
                            .add('counselor-map-counselors-list')
                    })

                Array
                    .from(this.el.querySelectorAll(`[aria-controls="${
                        node.getAttribute('id')}"]`))
                    .filter(el => el.parentNode
                        && el.parentNode.tagName.match(/label/i)
                        && el.parentNode.parentNode)
                    .forEach(el => {
                        el.removeAttribute('aria-selected')
                        el.removeAttribute('data-for')

                        el.setAttribute('role', 'button')
                        el.setAttribute('tabindex', 0)

                        if (el.parentNode.classList.contains('counselor-map-btn'))
                            el.classList.add('counselor-map-btn')

                        el.parentNode.parentNode
                            .insertBefore(el, el.parentNode)

                        el.nextElementSibling.remove()
                    })
            })
    }

    render () {
        this.renderAccessibilePanels()

        this.getButtons()
            .forEach(el => el.setAttribute('tabindex', 0))
    }
}
