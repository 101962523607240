import Component from '../../lib/component.js'

export default class ChecklistComponent extends Component {
    static scrollListener () {
        return true
    }

    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            aside: this.el.querySelector('.checklist-aside'),
            asideCard: this.el.querySelector('.checklist-aside > .card'),
            switch: this.el.querySelector('.checklist-aside-header .switch'),
            navLinks: Array.from(this.el
                .querySelectorAll('.checklist-aside ol a'))
                .filter(node => this.getStepIdFromHref(node))
                .filter(node => this.el
                    .querySelector(`.checklist-content article${
                        this.getStepIdFromHref(node)}`))
        }

        if (!this.els.switch)
            return

        this.onSwitchInputChange = this.onSwitchInputChange.bind(this)
        this.onNavLinkClick = this.onNavLinkClick.bind(this)
        this.onScroll = this.onScroll.bind(this)

        this.onSwitchInputChange()
        this.listen()
        this.render()
    }

    listen () {
        this.els.switch
            .addEventListener('change', this.onSwitchInputChange, false)

        this.els.navLinks
            .forEach(node => node
                .addEventListener('click', this.onNavLinkClick, false))

        if (!this.App.Scroller)
            return

        this.App.Scroller.state
            .on('update', this.onScroll)

        this.on('resize', this.onScroll)
    }

    onScroll (changed = this.App.Scroller.state.get()) {
        this.els.navLinks
            .forEach(node => {
                if (this.App.Scroller.scrollingTo)
                    return

                const article = this.el
                    .querySelector(`.checklist-content article${
                        this.getStepIdFromHref(node)}`)
                const articleBounds = article.getBoundingClientRect()

                if (articleBounds.top <= 0
                        && articleBounds.top + articleBounds.height > 0
                        && node !== this.getActiveStep())
                    this.setActiveStep(node)
            })

        const elBounds = this.el.getBoundingClientRect()
        const asideBounds = this.els.aside.getBoundingClientRect()

        const getStyle = (el, prop) => Number(((window
            .getComputedStyle(el) || {})[prop] || '0px')
            .replace(/px/g, ''))

        const isSticky = this.els.asideCard.classList.contains('sticky')
        const isStickyBottom = this.els.asideCard.classList
            .contains('sticky-bottom')

        const isBottom = elBounds.height + elBounds.top < (
            getStyle(this.els.aside, 'paddingTop')
            + getStyle(this.el, 'paddingBottom'))
                + this.els.asideCard.clientHeight

        const getStickyCardWidth = () => asideBounds.width
            - (getStyle(this.els.aside, 'paddingLeft')
            + getStyle(this.els.aside, 'paddingRight'))

        const setStickyCardWidth = () => {
            this.els.asideCard.style.width = `${getStickyCardWidth()}px`
        }

        const resetStickyState = () => {
            this.els.asideCard.removeAttribute('style')
            this.els.asideCard.classList.remove('sticky-bottom')
            this.els.asideCard.classList.remove('sticky')
        }

        if (this.els.asideCard.clientWidth !== getStickyCardWidth())
            setStickyCardWidth()

        if (this.els.asideCard.clientHeight
                + getStyle(this.els.aside, 'paddingTop')
                    >= window.innerHeight)
            return isSticky || isStickyBottom
                ? resetStickyState()
                : null

        if (isBottom)
            this.els.asideCard.classList.add('sticky-bottom')

        if (isSticky
                && (asideBounds.top > 0
                || isBottom))
            this.els.asideCard.classList.remove('sticky')

        if (!isSticky && asideBounds.top <= 0 && !isBottom) {
            this.els.asideCard.classList.remove('sticky-bottom')
            this.els.asideCard.classList.add('sticky')
        }
    }

    onSwitchInputChange (e) {
        if (!this.els.switch)
            return

        const isListView = this.getActiveView() === 'list'

        isListView
            ? this.onListViewActive()
            : this.onTextViewActive()

        this.el
            .setAttribute('data-view', this.getActiveView())

        const labelPrefix = `${this.els.switch
            .getAttribute('data-aria-label-prefix')} ` || ''

        this.els.switch
            .setAttribute('aria-label', `${labelPrefix}${isListView
                ? 'List'
                : 'Text'} View`)

        this.els.switch
            .setAttribute('title', `Toggle to switch to ${isListView
                ? 'Text'
                : 'List'} View`)

        if (this.els.aside.clientWidth
                === this.el.querySelector('.checklist-content').clientWidth)
            return

        const article = this.getStepIdFromHref(this.getActiveStep())
            ? this.el.querySelector(`.checklist-content article${
                this.getStepIdFromHref(this.getActiveStep())}`)
            : null

        if (!article || !this.App.Scroller)
            return

        this.App.Scroller
            .scrollTo({
                y: article.getBoundingClientRect().top
                    + this.App.Scroller.state.get('scrollY')
            })
    }

    onNavLinkClick (e) {
        e.preventDefault()

        if (!this.App.Scroller)
            return

        const article = this.getStepIdFromHref(e.target)
            ? this.el.querySelector(`.checklist-content article${
                this.getStepIdFromHref(e.target)}`)
            : null

        if (!article)
            return

        this.setActiveStep(e.target)

        this.App.Scroller
            .scrollTo({
                y: article.getBoundingClientRect().top
                    + this.App.Scroller.state.get('scrollY')
            })
            .then(() => {
                article.setAttribute('tabindex', -1)
                article.focus()
            })
    }

    onListViewActive () {
        this.getDetails()
            .forEach(node => node.removeAttribute('open'))
    }

    onTextViewActive () {
        this.getDetails()
            .forEach(node => node.setAttribute('open', true))
    }

    getDetails () {
        return Array.from(this.el.querySelectorAll('article details'))
    }

    getActiveView () {
        return this.els.switch.getAttribute('aria-checked') === 'true'
            ? 'text'
            : 'list'
    }

    getActiveStep () {
        return this.el
            .querySelector('.checklist-aside ol a[aria-current="step"]')
    }

    getStepIdFromHref (el) {
        return el && el.getAttribute('href')
            ? el.getAttribute('href')
                .replace(/.+(#.+)$/, '$1')
            : null
    }

    setActiveStep (node) {
        if (!node || node === this.getActiveStep())
            return

        if (this.getActiveStep() && this.getActiveStep().parentNode)
            this.getActiveStep().parentNode
                .classList.remove('active')

        if (this.getActiveStep())
            this.getActiveStep()
                .removeAttribute('aria-current')

        node.setAttribute('aria-current', 'step')

        if (node.parentNode)
            node.parentNode.classList.add('active')
    }

    render () {
        const hash = window.location.hash
        const navLink = this.els.navLinks
            .filter(el => this.getStepIdFromHref(el) === hash)[0]

        if (!hash || !navLink)
            return this.onScroll()

        navLink.click()

        if (!('history' in window)) {
            window.location.hash = ''
            return
        }

        window.history.replaceState(null, null,
            window.location.href.replace(window.location.hash, ''))
    }
}
