import Component from '../../lib/component.js'
import Pikaday from 'node_modules/pikaday'
import fecha from 'node_modules/fecha'

export default class DatepickerComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.format = 'YYYY-MM-DD'
        this.addFeatureTest({
            dateinput: () => {
                const input = document.createElement('input')
                input.setAttribute('type', 'date')
                input.style.display = 'none'
                document.body.appendChild(input)

                const invalidValue = 'not-a-date'
                input.setAttribute('value', invalidValue)
                const inputValue = input.value

                input.remove()

                return inputValue !== invalidValue
            }
        })

        this.els = {
            input: this.el.querySelector('input.datepicker-field'),
            date: null,
            mindep: this.props.bindMinSelectorId
                    && document.getElementById(this.props.bindMinSelectorId)
                ? document.getElementById(this.props.bindMinSelectorId)
                : null
        }

        if (!this.els.input)
            return console.warn('Datepicker exiting, no input to bind to')

        if (!this.els.input.id)
            this.els.input.id = `datepicker-${this.generateGUID()}`

        this.renderDate()

        if (this.isNativeDatepicker())
            this.els.input.setAttribute('type', 'date')

        this.onSelectPickaday = this.onSelectPickaday.bind(this)
        this.onDrawPickaday = this.onDrawPickaday.bind(this)

        this.Pickaday = this.isNativeDatepicker()
            ? null
            : new Pikaday({
                container: this.el,
                format: this.format,
                onDraw: this.onDrawPickaday,
                onSelect: this.onSelectPickaday,
                reposition: false,
                i18n: {
                    previousMonth: 'Previous Month',
                    nextMonth: 'Next Month',
                    months: fecha.i18n.monthNames,
                    weekdays: fecha.i18n.dayNames,
                    weekdaysShort: [ 'Su', 'M', 'T', 'W', 'Th', 'F', 'Sa' ]
                }
            })

        this.setMin()
        this.onChange()
        this.listen()
    }

    listen () {
        this.els.input
            .addEventListener('change', (e) => this.onChange(e))

        if (this.Pickaday)
            Array.from(this.el
                .querySelectorAll(`label[for="${this.els.input.id}"]`))
                .forEach((node) => {
                    node.onclick = () => this.el.classList.toggle('active')
                })

        if (this.els.mindep)
            this.els.mindep
                .addEventListener('change', (e) => this.setMin(e))
    }

    onChange (e) {
        if (this.Pickaday
                && this.Pickaday.getDate() !== this.els.input.value)
            this.Pickaday
                .setDate(this.parseFormattedDate(this.els.input.value))

        this.renderDate()
    }

    onSelectPickaday (e) {
        if (this.els.input.value === this.getFormattedDate(e))
            return

        this.els.input
            .setAttribute('value', this.getFormattedDate(e))

        this.onChange()
    }

    onDrawPickaday (e) {
        if (this.props.pikaAddClassnames
                && !e.el.className.match(this.props.pikaAddClassnames))
            e.el.className += ` ${this.props.pikaAddClassnames}`

        const buttons = Array.from(e.el.querySelectorAll('button'))

        // Style Day Buttons
        buttons
            .filter(node => node.classList.contains('pika-day'))
            .forEach((node) => {
                if (node.parentNode.classList.contains('is-disabled'))
                    node.disabled = true

                node.classList.add('btn-round')
                node.classList.add('transparent-bg')
                node.classList.add('dark-gray-3')
            })

        // Style Next/Prev Buttons
        buttons
            .filter(node => node.classList.contains('pika-prev')
                || node.classList.contains('pika-next'))
            .forEach((node) => {
                if (node.classList.contains('is-disabled'))
                    node.disabled = true

                node.classList.add('btn-round')
                node.classList.add('white-bg')
                node.classList.add(node.classList.contains('pika-prev')
                    ? 'mu-icon-chevron-left'
                    : 'mu-icon-chevron-right')

                node.setAttribute('aria-label', node.innerText)
                node.innerText = ''
            })

        // Don't style cal table
        e.el.querySelector('table')
            .classList.add('unstyled')

        e.el.querySelector('.pika-lendar')
            .classList.add('pika-visible')

        // Init
        if (!this.el.contains(e.el))
            this.el.appendChild(e.el)
    }

    getFormattedDate (dateObj, formatStr) {
        return fecha.format(dateObj || new Date(),
            formatStr || this.format)
    }

    parseFormattedDate (dateStr, formatStr) {
        return fecha.parse(dateStr || '',
            formatStr || this.format)
    }

    getMin (formatStr) {
        return this.els.mindep && this.els.mindep.value
            ? this.parseFormattedDate(this.els.mindep.value, formatStr)
            : new Date()
    }

    setMin () {
        if (this.isNativeDatepicker())
            return this.els.input
                .setAttribute('min', this
                    .getFormattedDate(this.getMin()))

        if (!this.Pickaday)
            return

        this.Pickaday.setMinDate(this.getMin())

        if (!this.els.input.value)
            this.Pickaday.gotoDate(this.getMin())
    }

    isNativeDatepicker () {
        return this.getFeatures().touch
            && this.getFeatures().dateinput
    }

    renderDate () {
        if (!this.els.date) {
            this.els.date = document.createElement('label')
            this.els.date.setAttribute('for', this.els.input.id)
            this.els.date
                .classList.add('datepicker-date')
            this.els.date
                .classList.add('display-block')
            this.els.date
                .classList.add('pad')
            this.el.appendChild(this.els.date)
        }

        const dateObj = this.els.input.value
            ? this.parseFormattedDate(this.els.input.value)
            : null

        this.els.date.innerHTML = `<span class="light-gray-4">${
            dateObj
                ? this.getFormattedDate(dateObj, 'dddd')
                : this.props.emptyMsgDay || '&nbsp;'
        }</span><span class="heading-5">${
            dateObj
                ? this.getFormattedDate(dateObj, 'MMMM D, YYYY')
                : this.props.emptyMsgDate || '&nbsp;'}</span>`
    }

    renderInputDateAsPicker () {
        console.log('render input date as picker')
    }
}
