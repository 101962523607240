import Component from '../../lib/component.js'
import SelectComponent from '../select/select'

export default class SlateFormComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.render()
        this.listen()
    }

    listen () {
        this.observer = new window
            .MutationObserver(mutations => this.render())

        this.observer.observe(this.el, { childList: true })
    }

    renderSelect (node) {
        const select = document.createElement('div')
        select.setAttribute('data-rendered', true)
        select.classList.add('select')
        node.parentNode.insertBefore(select, node)
        select.appendChild(node)

        console.groupCollapsed(SelectComponent.name)
        const Component = new SelectComponent({
            el: select,
            els: {},
            props: select.dataset,
            App: this.App
        })
        console.log(Component)
        console.groupEnd()
    }

    render () {
        Array.from(this.el.querySelectorAll('select'))
            .filter(node => !node.parentNode.getAttribute('data-rendered'))
            .forEach(node => this.renderSelect(node))
    }
}
