import Component from '../../lib/component.js'

export default class QueryComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.els = {
            checkboxes: this.el.querySelector('[data-query-sidebar]'),
            sidebar: null
        }

        if (!this.els.checkboxes)
            return

        this.render()
        this.listen()
    }

    listen () {
        Array.from(this.els.checkboxes.querySelectorAll('[type=checkbox]'))
            .forEach((node) => {
                node.setAttribute('data-sidebar-id',
                    `${node.id}-query-sidebar`)
                node.onchange = (e) => this.onChange(e)
            })

        Array.from(this.sidebar.querySelectorAll('[type=checkbox]'))
            .forEach((node) => {
                node.onchange = (e) => this.onChange(e)
            })
    }

    onChange (e) {
        const bindedCheckbox = document.getElementById(
            e.target.getAttribute('data-form-id')
                || e.target.getAttribute('data-sidebard-id'))

        if (!bindedCheckbox
                || bindedCheckbox.checked === e.target.checked)
            return

        bindedCheckbox.checked = e.target.checked
    }

    render () {
        const layoutEl = document.createElement('div')
        this.sidebar = this.els.checkboxes.cloneNode(true)

        layoutEl.className = 'row'
        layoutEl.innerHTML = `
            <div
                class="query-sidebar col-auto pad-5-t pad-3-r white-bg bg-offscreen-left display-none-lg-down"
            ></div>
            <div class="col query-content"></div>`

        Array.from(this.sidebar.querySelectorAll('[id]'))
            .forEach((node) => {
                const id = node.id

                node.id = `${id}-query-sidebar`
                node.setAttribute('data-form-id', id)

                Array.from(this.sidebar.querySelectorAll(`label[for="${id}"]`))
                    .forEach((label) => {
                        label.setAttribute('for', node.id)
                    })

                node.parentNode.classList
                    .remove('display-inline-block')
                node.parentNode.classList
                    .remove('margin-1-b')
                node.parentNode.classList
                    .remove('margin-r')

                node.parentNode.classList
                    .add('display-block')
                node.parentNode.classList
                    .add('margin-b')
            })

        this.sidebar.classList
            .remove('col-12')

        layoutEl
            .querySelector('.query-sidebar')
            .appendChild(this.sidebar)

        layoutEl
            .querySelector('.query-content')
            .classList.add('pad-4-l-lg-up')

        this.el.appendChild(layoutEl)
        this.els.checkboxes.classList
            .add('display-none-lg-up')

        Array.from(this.el.childNodes)
            .filter(node => node !== layoutEl)
            .forEach(node => layoutEl
                .querySelector('.query-content')
                .appendChild(node))
    }
}
