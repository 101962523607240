import Component from '../../lib/component.js'
import 'node_modules/mediaelement'

export default class MediaComponent extends Component {
    constructor (opts) {
        super(opts)

        if (!this.el)
            return

        this.addFeaturesToClassName()

        this.els = {
            media: this.el.querySelector('video')
                || this.el.querySelector('audio')
        }

        if (!this.els.media)
            return console.warn('Media exiting, no media to bind to')

        this.props.title = this.els.media.title
        this.onPlay = this.onPlay.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onError = this.onError.bind(this)
        this.Player = null

        const isYouTubeControls = this.props.youtubeControls
            && this.props.youtubeControls !== 'false'

        if (isYouTubeControls)
            this.el.classList.add('media-chromeless')

        new Promise(resolve =>
            new window.MediaElementPlayer(this.els.media, {
                classPrefix: 'media-',
                stretching: 'responsive',
                videoVolume: 'horizontal',
                youtube: {
                    controls: isYouTubeControls
                        ? 1
                        : 0
                },
                success: (media, node, player) => resolve(player),
                error: this.onError
            }))
            .then((fulfilled) => {
                this.Player = fulfilled
                this.listen()
            })
            .catch(console.warn(this.onError))
    }

    listen () {
        this.Player.media
            .addEventListener('playing', this.onPlay)

        this.Player.media
            .addEventListener('pause', this.onPause)

        this.Player.media
            .addEventListener('end', this.onPause)

        if (this.getFeatures().touch)
            Array.from(this.el
                .querySelectorAll('.media-captions-selector-list-item label'))
                .forEach((node) => {
                    node.ontouchstart = node.click
                })
    }

    onPlay (e) {
        if (!this.el.classList.contains('media-dirty'))
            this.el.classList.add('media-dirty')

        this.el.classList.add('media-playing')
    }

    onPause (e) {
        this.el.classList.remove('media-playing')
    }

    onError (media, node, player) {
        this.el.classList.add('media-error')

        const errorMsg = document.createElement('div')

        errorMsg.className = 'media-error-msg text-align-center display-flex '
            + 'flex-align-items-center flex-justify-content-center margin-0 '
            + (this.el.querySelector('video')
                ? 'heading-4'
                : 'heading-6')

        errorMsg.innerHTML = `
            <span class="red mu-icon-exclamation-circle margin-1-r"></span>
            <span class="white">Error loading media</span>
        `

        this.el.appendChild(errorMsg)
    }

    render (opts) {
    }
}
